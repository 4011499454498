import React, { useState } from "react";
import * as XLSX from "xlsx";
import { useLoading } from "../../contexts/LoadingContext";

import "./UploadPlanilha.css";

const extensoesPermitidas = [".xlsx", ".xls"];

const UploadPlanilha = ({
  onEntryDataProcessed,
  onLeaveDataProcessed,
  PJ = false,
  setPrograma,
  setEmpresa,
}) => {
  const [fileEntry, setFileEntry] = useState(null);
  const [fileLeave, setFileLeave] = useState(null);
  const [programaLocal, setProgramaLocal] = useState("");
  const [empresaLocal, setEmpresaLocal] = useState("");

  const { setLoading } = useLoading();

  const validarExtensao = (nomeArquivo) => {
    const extensao = nomeArquivo.slice(nomeArquivo.lastIndexOf(".")).toLowerCase();
    return extensoesPermitidas.includes(extensao);
  };

  const handleFileEntryUpload = (event) => {
    const file = event.target.files[0];
    if (file && !validarExtensao(file.name)) {
      alert("Arquivo de entrada inválido. Por favor, selecione uma planilha .xlsx ou .xls");
      return;
    }
    setFileEntry(file);
  };

  const handleFileLeaveUpload = (event) => {
    const file = event.target.files[0];
    if (file && !validarExtensao(file.name)) {
      alert("Arquivo de saída inválido. Por favor, selecione uma planilha .xlsx ou .xls");
      return;
    }
    setFileLeave(file);
  };

  const processFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const binaryStr = e.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet);
          resolve(data);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = () => reject(reader.error);
      reader.readAsBinaryString(file);
    });
  };

  const processFiles = async () => {
    if (!fileEntry || !fileLeave) return;
    if (!programaLocal || (PJ && !empresaLocal)) {
      alert("Preencha todos os campos obrigatórios.");
      return;
    }

    setLoading(true, "lendo planilhas");

    try {
      const [dadosEntrada, dadosSaida] = await Promise.all([
        processFile(fileEntry),
        processFile(fileLeave),
      ]);

      onEntryDataProcessed(dadosEntrada);
      onLeaveDataProcessed(dadosSaida);

      setPrograma(programaLocal);
      if (PJ) setEmpresa(empresaLocal);
    } catch (error) {
      console.error("Erro ao ler planilhas:", error);
      alert("Erro ao processar os arquivos. Verifique se os arquivos estão corretos.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="upload-container">
      <h2>Upload das Planilhas (Entrada e Saída)</h2>

      <div>
        <label>Nome do Programa:</label>
        <input
          type="text"
          placeholder="Ex: Trilha de Liderança"
          value={programaLocal}
          onChange={(e) => setProgramaLocal(e.target.value)}
        />
      </div>

      {PJ && (
        <div>
          <label>Nome da Empresa:</label>
          <input
            type="text"
            placeholder="Ex: XPTO Ltda."
            value={empresaLocal}
            onChange={(e) => setEmpresaLocal(e.target.value)}
          />
        </div>
      )}

      <div>
        <label>Planilha de Entrada:</label>
        <input type="file" accept=".xlsx,.xls" onChange={handleFileEntryUpload} />
      </div>

      <div>
        <label>Planilha de Saída:</label>
        <input type="file" accept=".xlsx,.xls" onChange={handleFileLeaveUpload} />
      </div>

      <button
        onClick={processFiles}
        disabled={!fileEntry || !fileLeave || !programaLocal || (PJ && !empresaLocal)}
      >
        Ler Planilhas
      </button>
    </div>
  );
};

export default UploadPlanilha;