import React, { useState } from "react";
import { Page, Text, View, Document, pdf, PDFViewer, Image } from "@react-pdf/renderer";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import { useLoading } from "../../contexts/LoadingContext";

import pdfStyles from "./pdfStyles";
import "./PDFGenerator.css";

import imagesBase64 from "../../assets/images";

const perguntaParaCategoria = {
  "Em uma escala de 1 a 10, sinto que posso decidir o que fazer nas minhas tarefas do dia a dia.": { categoria: "Processo", subcategoria: "Autonomia" },
  "Em uma escala de 1 a 10, tenho liberdade para organizar meus horários e minha agenda como eu prefiro.": { categoria: "Processo", subcategoria: "Autonomia" },
  "Em uma escala de 1 a 10, tenho a liberdade de escolher com quem vou trabalhar e como dividir as tarefas.": { categoria: "Processo", subcategoria: "Autonomia" },
  "Em uma escala de 1 a 10, sinto que posso escolher como realizar meu trabalho da melhor forma possível.": { categoria: "Processo", subcategoria: "Autonomia" },
  "Em uma escala de 1 a 10, consigo tomar iniciativa para alcançar minhas metas, sem depender de instruções o tempo todo.": { categoria: "Perfil", subcategoria: "NA" },
  "Em uma escala de 1 a 10, consigo priorizar o que é mais importante para atingir meus objetivos.": { categoria: "Produtividade", subcategoria: "NA" },
  "Em uma escala de 1 a 10, sinto que ajudo minha equipe a se desenvolver, para que todos possam trabalhar com mais independência.": { categoria: "Pessoas", subcategoria: "NA" },
  "Em uma escala de 1 a 10, tenho habilidade para lidar com os erros e extrair lições para uma evolução contínua.": { categoria: "Perfil", subcategoria: "NA" },
  "Em uma escala de 1 a 10, meu gestor me orienta adequadamente, permitindo-me tomar decisões por conta própria.": { categoria: "Liderança", subcategoria: "NA" },
  "Em uma escala de 1 a 10, meu líder confia em sua própria autonomia e não precisa depender de outro gestor o tempo todo.": { categoria: "Liderança", subcategoria: "NA" },
  "Em uma escala de 1 a 10, eu me conheço bem, entendo meus sentimentos e reconheço minhas fraquezas.": { categoria: "Perfil", subcategoria: "NA" },
  "Em uma escala de 1 a 10, minha rotina é equilibrada e eu acordo animado(a) para o dia.": { categoria: "Produtividade", subcategoria: "NA" },
  "Em uma escala de 1 a 10, reconheço minhas qualidades e valores e sei como eles me ajudam a alcançar melhores resultados.": { categoria: "Perfil", subcategoria: "NA" },
  "Em uma escala de 1 a 10, me sinto otimista com o futuro e orgulhoso(a) do que já conquistei.": { categoria: "Propósito", subcategoria: "NA" },
  "Em uma escala de 1 a 10, consigo analisar meus erros sem me criticar demais, aprendendo com eles.": { categoria: "Perfil", subcategoria: "NA" },
  "Em uma escala de 1 a 10, sinto que meu trabalho está conectado com o meu propósito e isso me dá orgulho.": { categoria: "Propósito", subcategoria: "NA" },
  "Em uma escala de 1 a 10, sou capaz de definir meus objetivos e direcionar minhas emoções para alcançá-los.": { categoria: "Perfil", subcategoria: "NA" },
  "Em uma escala de 1 a 10, reconheço o que os outros estão sentindo e uso a empatia para me conectar melhor com eles.": { categoria: "Pessoas", subcategoria: "NA" },
  "Em uma escala de 1 a 10, me relaciono bem com as pessoas, me comunico de forma clara e presto atenção no que os outros precisam.": { categoria: "Pessoas", subcategoria: "Comunicação" },
  "Em uma escala de 1 a 10, sou capaz de liderar e coordenar um grupo para atingir os resultados esperados.": { categoria: "Pessoas", subcategoria: "NA" },
  "Em uma escala de 1 a 10, recebo feedbacks úteis do meu gestor com frequência e de forma organizada.": { categoria: "Liderança", subcategoria: "NA" },
  "Em uma escala de 1 a 10, sou aberto(a) a feedbacks e sempre me comprometo com os planos de ação combinados.": { categoria: "Perfil", subcategoria: "Comunicação" },
  "Em uma escala de 1 a 10, estou sempre disponível para ouvir e contribuir com uma comunicação clara e eficiente dentro do time.": { categoria: "Pessoas", subcategoria: "Comunicação" },
  "Em uma escala de 1 a 10, as informações importantes são compartilhadas de forma clara e rápida na empresa em que eu trabalho.": { categoria: "Processo", subcategoria: "Comunicação" },
  "Em uma escala de 1 a 10, me sinto ouvido(a) pelo meu gestor.": { categoria: "Liderança", subcategoria: "NA" },
  "Em uma escala de 1 a 10, sou feliz com o trabalho que faço na empresa.": { categoria: "Propósito", subcategoria: "NA" },
  "Em uma escala de 1 a 10, me sinto feliz por trabalhar na empresa em que eu trabalho.": { categoria: "Propósito", subcategoria: "NA" },
  "Em uma escala de 1 a 10, reconheço as habilidades de cada pessoa do meu time e sei como elas ajudam nos resultados.": { categoria: "Pessoas", subcategoria: "NA" },
  "Em uma escala de 1 a 10, os processos da empresa em que eu trabalho incentiva a independência dos líderes e equipes.": { categoria: "Processo", subcategoria: "Autonomia" },
  "Em uma escala de 1 a 10, confio na visão e na postura do CEO da empresa em que eu trabalho.": { categoria: "Liderança", subcategoria: "NA" }
};

const calcularMedias = (dados) => {
  if (!Array.isArray(dados) || dados.length === 0) {
    console.warn("Dados inválidos ou vazios fornecidos para cálculo de médias.");
    return { resultados: {}, subResultados: {} };
  }

  const categorias = ["Perfil", "Pessoas", "Processo", "Produtividade", "Propósito"];
  const subcategorias = ["Autonomia", "Comunicação"];

  const resultados = {};
  const subResultados = {};

  categorias.forEach((categoria) => {
    const valores = [];

    dados.forEach((resposta) => {
      if (typeof resposta !== "object") return;

      Object.entries(resposta).forEach(([pergunta, valor]) => {
        try {
          const info = perguntaParaCategoria[pergunta];
          if (info && info.categoria === categoria && !isNaN(valor)) {
            valores.push(Number(valor));
          }
        } catch (e) {
          console.warn(`Erro ao processar pergunta "${pergunta}":`, e);
        }
      });
    });

    if (valores.length) {
      const media = (valores.reduce((acc, v) => acc + v, 0) / valores.length).toFixed(2);
      resultados[categoria] = media;
    }

    subcategorias.forEach((sub) => {
      const subValores = [];

      dados.forEach((resposta) => {
        if (typeof resposta !== "object") return;

        Object.entries(resposta).forEach(([pergunta, valor]) => {
          try {
            const info = perguntaParaCategoria[pergunta];
            if (
              info &&
              info.categoria === categoria &&
              info.subcategoria === sub &&
              !isNaN(valor)
            ) {
              subValores.push(Number(valor));
            }
          } catch (e) {
            console.warn(`Erro ao processar subcategoria "${sub}" na pergunta "${pergunta}":`, e);
          }
        });
      });

      if (subValores.length) {
        const mediaSub = (subValores.reduce((acc, v) => acc + v, 0) / subValores.length).toFixed(2);
        subResultados[`${categoria} - ${sub}`] = mediaSub;
      }
    });
  });

  return { resultados, subResultados };
};

const calcularMediasComLideranca = (dados) => {
  if (!Array.isArray(dados) || dados.length === 0) {
    console.warn("Dados inválidos ou vazios fornecidos para cálculo com liderança.");
    return { resultados: {}, subResultados: {} };
  }

  const categorias = ["Perfil", "Pessoas", "Processo", "Produtividade", "Propósito", "Liderança"];
  const subcategorias = ["Autonomia", "Comunicação"];

  const resultados = {};
  const subResultados = {};

  categorias.forEach((categoria) => {
    const valores = [];

    dados.forEach((resposta) => {
      if (typeof resposta !== "object") return;

      Object.entries(resposta).forEach(([pergunta, valor]) => {
        try {
          const info = perguntaParaCategoria[pergunta];
          if (info && info.categoria === categoria && !isNaN(valor)) {
            valores.push(Number(valor));
          }
        } catch (e) {
          console.warn(`Erro ao processar pergunta "${pergunta}":`, e);
        }
      });
    });

    if (valores.length) {
      const media = (valores.reduce((acc, v) => acc + v, 0) / valores.length).toFixed(2);
      resultados[categoria] = media;
    }

    subcategorias.forEach((sub) => {
      const subValores = [];

      dados.forEach((resposta) => {
        if (typeof resposta !== "object") return;

        Object.entries(resposta).forEach(([pergunta, valor]) => {
          try {
            const info = perguntaParaCategoria[pergunta];
            if (
              info &&
              info.categoria === categoria &&
              info.subcategoria === sub &&
              !isNaN(valor)
            ) {
              subValores.push(Number(valor));
            }
          } catch (e) {
            console.warn(`Erro ao processar subcategoria "${sub}" na pergunta "${pergunta}":`, e);
          }
        });
      });

      if (subValores.length) {
        const mediaSub = (subValores.reduce((acc, v) => acc + v, 0) / subValores.length).toFixed(2);
        subResultados[`${categoria} - ${sub}`] = mediaSub;
      }
    });
  });

  return { resultados, subResultados };
};

const PDFDocument = ({ entradaResumo, saidaResumo, nomeParticipante, programa, dataEntrada, dataSaida}) => {
  if (!entradaResumo || !saidaResumo) return null;

  const mediaEntrada =
    Object.values(entradaResumo.resultados)
      .map((v) => parseFloat(v))
      .reduce((acc, val) => acc + val, 0) / Object.keys(entradaResumo.resultados).length;

  const mediaSaida =
    Object.values(saidaResumo.resultados)
      .map((v) => parseFloat(v))
      .reduce((acc, val) => acc + val, 0) / Object.keys(saidaResumo.resultados).length;

  const ganhoPercentual = mediaEntrada !== 0
    ? ((mediaSaida - mediaEntrada) / mediaEntrada) * 100
    : 0;
    
  const todosValores = [];

  Object.entries(entradaResumo.resultados).forEach(([categoria, valor]) => {
    todosValores.push({
      categoria,
      tipo: "Entrada",
      valor: parseFloat(valor),
      valorComparativo: parseFloat(saidaResumo.resultados[categoria]),
    });
  });

  Object.entries(saidaResumo.resultados).forEach(([categoria, valor]) => {
    todosValores.push({
      categoria,
      tipo: "Saída",
      valor: parseFloat(valor),
      valorComparativo: parseFloat(entradaResumo.resultados[categoria]),
    });
  });

  const maior = todosValores.reduce((a, b) => (a.valor > b.valor ? a : b));
  const menor = todosValores.reduce((a, b) => (a.valor < b.valor ? a : b));

  const todosSubValores = [];

  Object.entries(entradaResumo.subResultados).forEach(([chave, valor]) => {
    todosSubValores.push({
      chave,
      tipo: "Entrada",
      valor: parseFloat(valor),
      valorComparativo: parseFloat(saidaResumo.subResultados[chave]),
    });
  });

  Object.entries(saidaResumo.subResultados).forEach(([chave, valor]) => {
    todosSubValores.push({
      chave,
      tipo: "Saída",
      valor: parseFloat(valor),
      valorComparativo: parseFloat(entradaResumo.subResultados[chave]),
    });
  });

  const maiorSub = todosSubValores.reduce((a, b) => (a.valor > b.valor ? a : b));
  const menorSub = todosSubValores.reduce((a, b) => (a.valor < b.valor ? a : b));

  const percentualMaior = maior.valorComparativo !== 0
    ? ((maior.valor - maior.valorComparativo) / maior.valorComparativo) * 100
    : 0;

  const percentualMenor = menor.valorComparativo !== 0
    ? ((menor.valor - menor.valorComparativo) / menor.valorComparativo) * 100
    : 0;

  const valorParaPercentual = (valor) => {
    const percent = Math.round((valor / 10) * 100);
    const arredondado = Math.round(percent / 5) * 5;
    return Math.max(0, Math.min(arredondado, 100));
  };

  const getGraphImageName = (tipo, cor, valor) => {
    const percent = valorParaPercentual(valor);
    if (percent === 0) return tipo === "semi" ? "semiGraph0" : "fullGraph0";
    return `${tipo}Graph${cor.charAt(0).toUpperCase() + cor.slice(1)}${percent}`;
  };

  const getGraphImage = (tipo, cor, valor) => {
    const nome = getGraphImageName(tipo, cor, valor);
    return imagesBase64[nome] || imagesBase64[`${tipo}Graph0`];
  };

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.upBar}></View>

        <View style={pdfStyles.mainDiv}>
          <View style={pdfStyles.divTitle}>
            <Image src={imagesBase64.logo} style={pdfStyles.image} />
            <View style={pdfStyles.divTitleAux}>
              <Text style={pdfStyles.title}>Os 5 P's do Protagonismo</Text>
              <Text style={pdfStyles.subtitle}>
                {`São Paulo, ${new Date().toLocaleDateString('pt-BR', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}`}
              </Text>
            </View>
          </View>

          <View style={pdfStyles.divMid}>
            <View style={pdfStyles.divMidInner}>
              
              <View style={pdfStyles.barSection}>
                {Object.keys(entradaResumo.resultados).map((categoria) => {
                  const valorEntrada = parseFloat(entradaResumo.resultados[categoria]);
                  const valorSaida = parseFloat(saidaResumo.resultados[categoria]);

                  const diferenca = valorSaida - valorEntrada;

                  const icone = valorSaida > valorEntrada
                    ? imagesBase64.arrowUp
                    : valorSaida < valorEntrada
                    ? imagesBase64.arrowDown
                    : null;

                  return (
                    <View key={categoria} style={pdfStyles.barRow}>
                      <Text style={pdfStyles.barLabel}>{categoria}</Text>

                      <View style={pdfStyles.divBars}>
                        <View style={pdfStyles.divBarsAux}>
                          <View style={pdfStyles.barTrack}>
                            <View
                              style={[
                                pdfStyles.barFillEntrada,
                                { width: `${(valorEntrada / 10) * 100}%` },
                              ]}
                            />
                          </View>
                          <Text style={pdfStyles.barValue}>{valorEntrada.toFixed(1)}</Text>
                        </View>

                        <View style={pdfStyles.divBarsAux}>
                          <View style={pdfStyles.barTrack}>
                            <View
                              style={[
                                pdfStyles.barFillSaida,
                                { width: `${(valorSaida / 10) * 100}%` },
                              ]}
                            />
                          </View>
                          <Text style={pdfStyles.barValue}>{valorSaida.toFixed(1)}</Text>
                        </View>
                      </View>

                      <Image src={icone} style={{ width: 10, height: 10, marginLeft: 6 }} />
                    </View>
                  );
                })}
              </View>

              <View style={pdfStyles.scoreBox}>
                <View style={pdfStyles.upperGraphs}>

                  <View style={pdfStyles.upperGraphsTopRow}>
                    <Text style={pdfStyles.graphDelta}>
                      {ganhoPercentual >= 0 ? "+" : "-"}{Math.abs(ganhoPercentual).toFixed(1)}%
                    </Text>
                    <Text style={pdfStyles.graphPipe}>|</Text>
                    <Text style={pdfStyles.graphDeltaRaw}>
                      {(mediaSaida - mediaEntrada).toFixed(2)}
                    </Text>
                  </View>

                  <View style={pdfStyles.upperGraphsCircles}>
                    <View style={pdfStyles.graphWithValue}>

                      <Image
                          src={getGraphImage("semi", "pink", mediaEntrada)}
                          style={pdfStyles.semiGraph}
                        />

                      <Text style={pdfStyles.graphValue}>{mediaEntrada.toFixed(1)}</Text>
                    </View>

                    <View style={pdfStyles.graphWithValue}>

                      <Image
                        src={getGraphImage("semi", "green", mediaSaida)}
                        style={pdfStyles.semiGraph}
                      />

                      <Text style={pdfStyles.graphValue}>{mediaSaida.toFixed(1)}</Text>
                    </View>
                  </View>

                  <View style={pdfStyles.graphLine} />
                  <Text style={pdfStyles.scoreTitle}>Protagonismo Score</Text>

                </View>

                <View style={pdfStyles.bottomGraphs}>

                  {/* Menor indicador */}
                  <View style={pdfStyles.indicatorBox}>
                    {/* Calcular dinamicamente */}
                    {(() => {
                      const entrada = menor.tipo === "Entrada" ? menor.valor : menor.valorComparativo;
                      const saida = menor.tipo === "Saída" ? menor.valor : menor.valorComparativo;
                      const ganhoBruto = saida - entrada;
                      const percentual = entrada !== 0 ? (ganhoBruto / entrada) * 100 : 0;

                      return (
                        <>
                          <View style={pdfStyles.indicatorHeader}>
                            <Text style={pdfStyles.graphDelta}>
                              {percentual >= 0 ? "+" : "-"}{Math.abs(percentual).toFixed(0)}%
                            </Text>
                            <Text style={pdfStyles.graphPipe}>|</Text>
                            <Text style={pdfStyles.graphDeltaRaw}>
                              {ganhoBruto >= 0 ? "+" : "-"}{Math.abs(ganhoBruto).toFixed(2)}
                            </Text>
                          </View>

                          <View style={pdfStyles.indicatorCircles}>
                            <View style={pdfStyles.circleGraphWrapper}>

                              <Image
                                src={getGraphImage("full", "pink", entrada)}
                                style={pdfStyles.circleGraph}
                              />

                              <Text style={pdfStyles.circleValue}>{entrada.toFixed(1)}</Text>
                              <Image src={imagesBase64.arrowDown} style={pdfStyles.circleArrow} />
                            </View>

                            <View style={pdfStyles.circleGraphWrapper}>

                              <Image
                                src={getGraphImage("full", "green", saida)}
                                style={pdfStyles.circleGraph}
                              />

                              <Text style={pdfStyles.circleValue}>{saida.toFixed(1)}</Text>
                              <Image src={imagesBase64.arrowUp} style={pdfStyles.circleArrow} />
                            </View>
                          </View>

                          <View style={pdfStyles.indicatorLine} />

                          <View style={pdfStyles.indicatorLabelWrapper}>
                            <Image src={imagesBase64.arrowDown} style={pdfStyles.arrowLabelIcon} />
                            <Text style={pdfStyles.indicatorLabel}>Menor indicador</Text>
                          </View>
                          <Text style={[pdfStyles.indicatorCategory, { color: "#e91e63" }]}>
                            {menor.categoria}
                          </Text>
                        </>
                      );
                    })()}
                  </View>

                  {/* Maior indicador */}
                  <View style={pdfStyles.indicatorBox}>
                    {(() => {
                      const entrada = maior.tipo === "Entrada" ? maior.valor : maior.valorComparativo;
                      const saida = maior.tipo === "Saída" ? maior.valor : maior.valorComparativo;
                      const ganhoBruto = saida - entrada;
                      const percentual = entrada !== 0 ? (ganhoBruto / entrada) * 100 : 0;

                      return (
                        <>
                          <View style={pdfStyles.indicatorHeader}>
                            <Text style={pdfStyles.graphDelta}>
                              {percentual >= 0 ? "+" : "-"}{Math.abs(percentual).toFixed(0)}%
                            </Text>
                            <Text style={pdfStyles.graphPipe}>|</Text>
                            <Text style={pdfStyles.graphDeltaRaw}>
                              {ganhoBruto >= 0 ? "+" : "-"}{Math.abs(ganhoBruto).toFixed(2)}
                            </Text>
                          </View>

                          <View style={pdfStyles.indicatorCircles}>
                            <View style={pdfStyles.circleGraphWrapper}>

                              <Image
                                src={getGraphImage("full", "pink", entrada)}
                                style={pdfStyles.circleGraph}
                              />

                              <Text style={pdfStyles.circleValue}>{entrada.toFixed(1)}</Text>
                              <Image src={imagesBase64.arrowDown} style={pdfStyles.circleArrow} />
                            </View>

                            <View style={pdfStyles.circleGraphWrapper}>

                              <Image
                                src={getGraphImage("full", "green", saida)}
                                style={pdfStyles.circleGraph}
                              />

                              <Text style={pdfStyles.circleValue}>{saida.toFixed(1)}</Text>
                              <Image src={imagesBase64.arrowUp} style={pdfStyles.circleArrow} />
                            </View>
                          </View>

                          <View style={pdfStyles.indicatorLine} />

                          <View style={pdfStyles.indicatorLabelWrapper}>
                            <Image src={imagesBase64.arrowUp} style={pdfStyles.arrowLabelIcon} />
                            <Text style={pdfStyles.indicatorLabel}>Maior indicador</Text>
                          </View>
                          <Text style={[pdfStyles.indicatorCategory, { color: "#009688" }]}>
                            {maior.categoria}
                          </Text>
                        </>
                      );
                    })()}
                  </View>

                </View>
                
              </View>

              <View style={pdfStyles.userDataBox}>
                <View>
                  <Text style={pdfStyles.userDataLabel}>Participante:</Text>
                  <Text style={pdfStyles.userDataTitle}>{ nomeParticipante }</Text>
                </View>

                <View>
                  <Text style={pdfStyles.userDataLabel}>Programa:</Text>
                  <Text style={pdfStyles.userDataTitle}>{ programa }</Text>
                </View>

                <View style={pdfStyles.legendBox}>
                  <View style={pdfStyles.legendItem}>
                    <View style={[pdfStyles.legendBar, { backgroundColor: "#e91d75" }]}>
                      <Text style={pdfStyles.legendText}>Entrada</Text>
                    </View>
                    <Text style={pdfStyles.legendDate}>{ dataEntrada }</Text>
                  </View>

                  <View style={pdfStyles.legendItem}>
                    <View style={[pdfStyles.legendBar, { backgroundColor: "#00afa9" }]}>
                      <Text style={pdfStyles.legendText}>Saída</Text>
                    </View>
                    <Text style={pdfStyles.legendDate}>{ dataSaida }</Text>
                  </View>
                </View>

              </View>

            </View>

            <View style={pdfStyles.divTextVertical}>
              <Text style={pdfStyles.verticalText}>Categorias</Text>
            </View>
          </View>

          <View style={pdfStyles.divBottom}>
            <View style={pdfStyles.aboutBox}>
              <View style={pdfStyles.aboutTitleRow}>
                <Image src={imagesBase64.sideImage} style={pdfStyles.aboutIcon} />
                <Text style={pdfStyles.aboutTitle}>Sobre os cinco P's</Text>
              </View>

              <Text style={pdfStyles.aboutText}>
                Nonnono nononon nononn nononn nonononononon ono onoon nonoon nonoon nononn nonononononon ono onoon nononon nononn nononononon ono onoon nonoon nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ononn.
              </Text>
            </View>

            <View style={pdfStyles.subDiv}>
              <View style={pdfStyles.subBarSection}>
                {Object.keys(entradaResumo.subResultados).map((chave) => {
                  const [categoria, sub] = chave.split(" - ");
                  const valorEntrada = parseFloat(entradaResumo.subResultados[chave]);
                  const valorSaida = parseFloat(saidaResumo.subResultados[chave]);

                  const icone = valorSaida > valorEntrada
                    ? imagesBase64.arrowUp
                    : valorSaida < valorEntrada
                    ? imagesBase64.arrowDown
                    : null;

                  return (
                    <View key={`sub-${chave}`} style={pdfStyles.subBarRow}>
                      <View style={pdfStyles.subLabelGroup}>
                        <Text style={pdfStyles.subTitleLabel}>{sub}</Text>
                        <Text style={pdfStyles.subCategoryLabel}>{categoria}</Text>
                      </View>

                      <View style={pdfStyles.subDivBars}>
                        <View style={pdfStyles.subDivBarsAux}>
                          <View style={pdfStyles.subBarTrack}>
                            <View
                              style={[
                                pdfStyles.subBarFillEntrada,
                                { width: `${(valorEntrada / 10) * 100}%` },
                              ]}
                            />
                          </View>
                          <Text style={pdfStyles.subBarValue}>{valorEntrada.toFixed(1)}</Text>
                        </View>

                        <View style={pdfStyles.subDivBarsAux}>
                          <View style={pdfStyles.subBarTrack}>
                            <View
                              style={[
                                pdfStyles.subBarFillSaida,
                                { width: `${(valorSaida / 10) * 100}%` },
                              ]}
                            />
                          </View>
                          <Text style={pdfStyles.subBarValue}>{valorSaida.toFixed(1)}</Text>
                        </View>
                      </View>

                      <Image src={icone} style={{ width: 10, height: 10, marginLeft: 6 }} />
                    </View>
                  );
                })}
              </View>

              <View style={pdfStyles.subCircleSection}>
                {/* Menor Subcategoria */}
                <View style={pdfStyles.indicatorBox}>
                  {(() => {
                    const entrada = menorSub.tipo === "Entrada" ? menorSub.valor : menorSub.valorComparativo;
                    const saida = menorSub.tipo === "Saída" ? menorSub.valor : menorSub.valorComparativo;
                    const ganhoBruto = saida - entrada;
                    const percentual = entrada !== 0 ? (ganhoBruto / entrada) * 100 : 0;

                    return (
                      <>
                        <View style={pdfStyles.indicatorHeader}>
                          <Text style={pdfStyles.graphDelta}>
                            {percentual >= 0 ? "+" : "-"}{Math.abs(percentual).toFixed(0)}%
                          </Text>
                          <Text style={pdfStyles.graphPipe}>|</Text>
                          <Text style={pdfStyles.graphRaw}>
                            {ganhoBruto >= 0 ? "+" : "-"}{Math.abs(ganhoBruto).toFixed(2)}
                          </Text>
                        </View>

                        <View style={pdfStyles.indicatorCircles}>
                          <View style={pdfStyles.circleGraphWrapper}>
                            <Image
                              src={getGraphImage("full", "pink", entrada)}
                              style={pdfStyles.circleGraph}
                            />
                            <Text style={pdfStyles.circleValue}>{entrada.toFixed(1)}</Text>
                            <Image src={imagesBase64.arrowDown} style={pdfStyles.circleArrow} />
                          </View>
                          <View style={pdfStyles.circleGraphWrapper}>
                            <Image
                              src={getGraphImage("full", "green", saida)}
                              style={pdfStyles.circleGraph}
                            />
                            <Text style={pdfStyles.circleValue}>{saida.toFixed(1)}</Text>
                            <Image src={imagesBase64.arrowUp} style={pdfStyles.circleArrow} />
                          </View>
                        </View>

                        <View style={pdfStyles.indicatorLine} />
                        <View style={pdfStyles.indicatorLabelWrapper}>
                          <Image src={imagesBase64.arrowDown} style={pdfStyles.arrowLabelIcon} />
                          <Text style={pdfStyles.indicatorLabel}>Menor subindicador</Text>
                        </View>
                        <Text style={[pdfStyles.indicatorCategory, { color: "#e91e63" }]}>
                          {menorSub.chave}
                        </Text>
                      </>
                    );
                  })()}
                </View>

                {/* Maior Subcategoria */}
                <View style={pdfStyles.indicatorBox}>
                  {(() => {
                    const entrada = maiorSub.tipo === "Entrada" ? maiorSub.valor : maiorSub.valorComparativo;
                    const saida = maiorSub.tipo === "Saída" ? maiorSub.valor : maiorSub.valorComparativo;
                    const ganhoBruto = saida - entrada;
                    const percentual = entrada !== 0 ? (ganhoBruto / entrada) * 100 : 0;

                    return (
                      <>
                        <View style={pdfStyles.indicatorHeader}>
                          <Text style={pdfStyles.graphDelta}>
                            {percentual >= 0 ? "+" : "-"}{Math.abs(percentual).toFixed(0)}%
                          </Text>
                          <Text style={pdfStyles.graphPipe}>|</Text>
                          <Text style={pdfStyles.graphRaw}>
                            {ganhoBruto >= 0 ? "+" : "-"}{Math.abs(ganhoBruto).toFixed(2)}
                          </Text>
                        </View>

                        <View style={pdfStyles.indicatorCircles}>
                          <View style={pdfStyles.circleGraphWrapper}>
                            <Image
                              src={getGraphImage("full", "pink", entrada)}
                              style={pdfStyles.circleGraph}
                            />
                            <Text style={pdfStyles.circleValue}>{entrada.toFixed(1)}</Text>
                            <Image src={imagesBase64.arrowDown} style={pdfStyles.circleArrow} />
                          </View>
                          <View style={pdfStyles.circleGraphWrapper}>
                            <Image
                              src={getGraphImage("full", "green", saida)}
                              style={pdfStyles.circleGraph}
                            />
                            <Text style={pdfStyles.circleValue}>{saida.toFixed(1)}</Text>
                            <Image src={imagesBase64.arrowUp} style={pdfStyles.circleArrow} />
                          </View>
                        </View>

                        <View style={pdfStyles.indicatorLine} />
                        <View style={pdfStyles.indicatorLabelWrapper}>
                          <Image src={imagesBase64.arrowUp} style={pdfStyles.arrowLabelIcon} />
                          <Text style={pdfStyles.indicatorLabel}>Maior subindicador</Text>
                        </View>
                        <Text style={[pdfStyles.indicatorCategory, { color: "#009688" }]}>
                          {maiorSub.chave}
                        </Text>
                      </>
                    );
                  })()}
                </View>
              </View>

            </View>

            <View style={[pdfStyles.aboutBox, { marginTop: 5 }]}>
              <View style={pdfStyles.aboutTitleRow}>
                <Image src={imagesBase64.sideImage} style={pdfStyles.aboutIcon} />
                <Text style={pdfStyles.aboutTitle}>Sobre as subcategorias</Text>
              </View>

              <Text style={pdfStyles.aboutText}>
                Nonnono nononon nononn nononn nonononononon ono onoon nonoon nonoon nononn nonononononon ono onoon nononon nononn nononononon ono onoon nonoon nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ono onoon nonoon nononn nononn nononononon ononn.
              </Text>
            </View>

            <View style={pdfStyles.divTextVerticalBottom}>
              <Text style={pdfStyles.verticalText}>SubCategorias</Text>
            </View>
          </View>

          <View style={pdfStyles.noteSection}>
            <View style={pdfStyles.noteBar} />
            <Text style={pdfStyles.noteText}>
              Proibida a reprodução total ou parcial deste relatório, seja qual for o meio, eletrônico ou impresso, sem a permissão expressa do <Text style={pdfStyles.noteBold}>Método Santé.</Text>
            </Text>
          </View>

          <Image src={imagesBase64.sideImage} style={pdfStyles.sideDecoration} />

        </View>

      </Page>

      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.upBar}></View>

        <View style={pdfStyles.mainDiv}>
          <View style={pdfStyles.divTitle}>
            <Image src={imagesBase64.logo} style={pdfStyles.image} />
            <View style={pdfStyles.divTitleAux}>
              <Text style={pdfStyles.title}>Os 5 P's do Protagonismo</Text>
              <Text style={pdfStyles.subtitle}>
                {`São Paulo, ${new Date().toLocaleDateString('pt-BR', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}`}
              </Text>
            </View>
          </View>

          <View style={pdfStyles.explanationContainer}>
            {[
              {
                titulo: "Perfil",
                pergunta: "Você se conhece?",
                imagem: imagesBase64.perfil,
                texto:
                  "O quanto você realmente se conhece? Autoconhecimento é a base para decisões conscientes, melhor gestão de desafios e desenvolvimento de habilidades. Compreender suas qualidades, valores e comportamentos permite usar suas aptidões de forma mais estratégica. Reconhecer limitações não significa aceitá-las como barreiras, mas vê-las como oportunidades de crescimento. Identificar pontos de melhoria possibilita desenvolver competências ou buscar apoio complementar. Essa clareza impacta sua comunicação e relacionamentos. Ao entender suas emoções e reações, você gerencia melhor as situações diárias. Quanto mais se conhece, mais confiança tem para agir alinhado ao que realmente faz sentido para você.",
              },
              {
                titulo: "Pessoas",
                pergunta: "Você conhece as pessoas ao seu redor?",
                imagem: imagesBase64.pessoas,
                texto:
                  "A forma como nos relacionamos reflete quem somos. No trabalho e na vida pessoal, interagimos com diferentes perfis e opiniões. O desafio não é apenas se conectar com quem pensa como nós, mas respeitar e valorizar as diferenças, tornando as relações mais produtivas. Vínculos autênticos exigem escuta ativa, empatia e disposição para compreender o outro. Muitas vezes, julgamos sem considerar motivações e contextos. Ao investir tempo em conhecer as pessoas, ampliamos nossa visão e fortalecemos a confiança. No ambiente organizacional, essa habilidade melhora a colaboração, evita conflitos e cria uma cultura mais saudável. Ninguém constrói resultados sozinho, e relações de qualidade são a base para grandes conquistas.",
              },
              {
                titulo: "Produtividade",
                pergunta: "Como está sua gestão do tempo?",
                imagem: imagesBase64.produtividade,
                texto:
                  "O tempo é valioso, mas difícil de gerenciar. Você controla sua rotina ou apenas apaga incêndios? Seu tempo é bem direcionado ou consumido por urgências e distrações? Produtividade não é fazer mais, mas sim fazer o que importa. Definir prioridades, manter o foco e evitar a procrastinação otimiza seu desempenho, reduz a sobrecarga mental e melhora resultados sem prejudicar sua qualidade de vida. Delegar também é essencial. Assumir tudo sozinho sobrecarrega e reduz a eficiência. Compartilhar responsabilidades permite focar no que agrega valor. Por fim, produtividade exige equilíbrio. Trabalhar sem pausas leva à exaustão. Administrar bem o tempo garante crescimento profissional sem comprometer o bem-estar.",
              },
              {
                titulo: "Processo",
                pergunta: "Como os processos impactam seu trabalho?",
                imagem: imagesBase64.processo,
                texto:
                  "Cada ação faz parte de um processo. Algumas fluem naturalmente, enquanto outras sofrem com burocracia, falhas na comunicação e falta de clareza. O desafio não é apenas seguir processos, mas aprimorá-los para tornar o trabalho mais eficiente e claro. Diante de atrasos ou falhas, buscar um culpado é comum, mas a causa real muitas vezes está em processos mal estruturados. Um olhar crítico ajuda a identificar melhorias e propor soluções eficazes. Compreender os processos conecta sua tarefa ao todo, permitindo decisões mais alinhadas aos objetivos da empresa. Líderes devem garantir processos eficientes, incentivando equipes a questionar, propor mudanças e otimizar continuamente. Processos bem desenhados não engessam, mas criam caminhos para inovação e crescimento.",
              },
              {
                titulo: "Propósito",
                pergunta: "O que te move?",
                imagem: imagesBase64.proposito,
                texto:
                  "Ter um propósito claro transforma a forma como encaramos o trabalho e a vida. Quando entendemos por que fazemos o que fazemos, encontramos mais motivação, resiliência e satisfação nas atividades diárias. O propósito está ligado ao sentimento de realização e ao impacto que queremos gerar. Quando esse sentido faz em si mesmo, ele se desenvolve em mentalidade otimista, mesmo diante de desafios. Ter um propósito não significa ter uma missão grandiosa. Muitas vezes, está nos pequenos detalhes: no impacto positivo, no aprendizado contínuo e na evolução pessoal. Conectados ao propósito, enfrentamos os desafios com mais determinação e fazemos nossas escolhas com mais significado.",
              },
            ].map((item) => (
              <View key={item.titulo} style={pdfStyles.explanationBlock}>
                <Image src={item.imagem} style={pdfStyles.explanationImage} />

                <View style={pdfStyles.explanationTextContainer}>
                  <View style={pdfStyles.explanationTitleRow}>
                    <Image src={imagesBase64.sideImage} style={pdfStyles.explanationIcon} />
                    <View style={pdfStyles.titleDiv}>
                      <Text style={pdfStyles.explanationTitleBold}>{item.titulo}:</Text>
                      <Text style={pdfStyles.explanationTitle}>{item.pergunta}</Text>
                    </View>
                  </View>

                  <Text style={pdfStyles.explanationParagraph}>{item.texto}</Text>

                  <View style={pdfStyles.dividerLine} />
                </View>
              </View>
            ))}
          </View>

          <View style={pdfStyles.noteSection}>
            <View style={pdfStyles.noteBar} />
            <Text style={pdfStyles.noteText}>
              Proibida a reprodução total ou parcial deste relatório, seja qual for o meio, eletrônico ou impresso, sem a permissão expressa do <Text style={pdfStyles.noteBold}>Método Santé.</Text>
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const PDFResumoCategorias = ({ entrada = [], saida = [], onFinalizado, PJ = false, programa, empresa }) => {
  const { setLoading } = useLoading();

  const formatarMesAno = (dataStr) => {
    const data = new Date(dataStr);
    if (isNaN(data)) return "";
    const meses = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];
    const mes = meses[data.getMonth()];
    const ano = String(data.getFullYear()).slice(-2);
    return `${mes}/${ano}`;
  };

  if (!Array.isArray(entrada) || !Array.isArray(saida) || entrada.length === 0 || saida.length === 0) {
    return null;
  }

  const agruparPorParticipante = (dados) => {
    return dados.reduce((acc, item) => {
      const primeiroNome = item['[Por favor, informe o seu nome completo .] Primeiro Nome']?.trim();
      const sobrenome = item['[Por favor, informe o seu nome completo .] Sobrenome']?.trim();
      const nomeCompleto = [primeiroNome, sobrenome].filter(Boolean).join(' ');
      if (nomeCompleto) {
        if (!acc[nomeCompleto]) acc[nomeCompleto] = [];
        acc[nomeCompleto].push(item);
      }
      return acc;
    }, {});
  };

  const entradaPorPessoa = agruparPorParticipante(entrada);
  const saidaPorPessoa = agruparPorParticipante(saida);
  const participantesCompletos = Object.keys(entradaPorPessoa).filter(nome => saidaPorPessoa[nome]);

  const gerarPDF = async () => {
    if (participantesCompletos.length === 0) {
      alert("Nenhum participante com dados completos foi encontrado.");
      return;
    }

    const zip = new JSZip();

    try {
      for (let i = 0; i < participantesCompletos.length; i++) {
        const nome = participantesCompletos[i];
        setLoading(true, `gerando relatório ${i + 1} de ${participantesCompletos.length}`);

        const entradaDados = entradaPorPessoa[nome];
        const saidaDados = saidaPorPessoa[nome];

        if (!entradaDados?.length || !saidaDados?.length) {
          console.warn(`Dados incompletos para o participante: ${nome}`);
          continue;
        }

        const entradaResumo = calcularMedias(entradaDados);
        const saidaResumo = calcularMedias(saidaDados);

        const pdfBlob = await pdf(
          <PDFDocument
            entradaResumo={entradaResumo}
            saidaResumo={saidaResumo}
            nomeParticipante={nome}
            programa={programa}
            dataEntrada={formatarMesAno(entradaDados[0]?.['Submission Date'])}
            dataSaida={formatarMesAno(saidaDados[0]?.['Submission Date'])}
          />
        ).toBlob();

        zip.file(`${nome}.pdf`, pdfBlob);
      }

      if (PJ) {
        setLoading(true, "gerando relatório institucional");

        const entradaResumoPJ = calcularMediasComLideranca(entrada);
        const saidaResumoPJ = calcularMediasComLideranca(saida);

        const pdfBlobPJ = await pdf(
          <PDFDocument
            entradaResumo={entradaResumoPJ}
            saidaResumo={saidaResumoPJ}
            nomeParticipante={empresa || "Institucional"}
            programa={programa}
            dataEntrada={formatarMesAno(entrada[0]?.['Submission Date'])}
            dataSaida={formatarMesAno(saida[0]?.['Submission Date'])}
          />
        ).toBlob();

        zip.file("Relatorio_Institucional.pdf", pdfBlobPJ);
      }

      setLoading(true, "compactando arquivos");
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "relatorios.zip");
    } catch (error) {
      console.error("Erro ao gerar os relatórios:", error);
      alert("Erro ao gerar os relatórios. Verifique os dados e tente novamente.");
    } finally {
      setLoading(false);
      if (onFinalizado) onFinalizado();
    }
  };

  const primeiroParticipante = participantesCompletos[0];
  if (!primeiroParticipante || !entradaPorPessoa[primeiroParticipante]?.length || !saidaPorPessoa[primeiroParticipante]?.length) {
    return (
      <div className="pdf-generator-container">
        <h2>Erro: dados inválidos</h2>
        <p>Verifique se as planilhas de entrada e saída estão no formato correto.</p>
      </div>
    );
  }

  return (
    <div className="pdf-generator-container">
      <h2>Gerar Resumo por Categoria</h2>
      <button onClick={gerarPDF} disabled={entrada.length === 0 || saida.length === 0}>
        Baixar ZIP com PDFs
      </button>
    </div>
  );
};

export default PDFResumoCategorias;