// src/pages/PDFGeneratorPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import UploadPlanilha from "../../components/leitor/UploadPlanilha";
import PDFResumoCategorias from "../../components/generatorPJ/PDFGeneratorPJ";
import GlobalLoader from "../../components/loader/GlobalLoader";
import ErrorBoundary from "../../components/error/ErrorBoundary";
import { LoadingProvider } from "../../contexts/LoadingContext";

import "./PDFGeneratorPage.css";

const PDFGeneratorPage = () => {
  const [tipo, setTipo] = useState(null);
  const [dataEntry, setDataEntry] = useState([]);
  const [dataLeave, setDataLeave] = useState([]);
  const [mostrarPDFResumo, setMostrarPDFResumo] = useState(false);

  const [programa, setPrograma] = useState("");
  const [empresa, setEmpresa] = useState("");
    
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };

  useEffect(() => {
    if (dataEntry.length > 0 && dataLeave.length > 0) {
      setMostrarPDFResumo(true);
    }
  }, [dataEntry, dataLeave]);

  return (
    <LoadingProvider>
      <GlobalLoader />

      <div className="app-container">
        <h1 className="app-title">Gerador de PDFs</h1>

        <div className="back-btn">
          <button onClick={handleBack}>
            Voltar
          </button>
        </div>

        <div className="tipo-selector">
          <button onClick={() => setTipo("PF")} disabled={tipo === "PF"}>
            Pessoa Física (PF)
          </button>
          <button onClick={() => setTipo("PJ")} disabled={tipo === "PJ"}>
            Pessoa Jurídica (PJ)
          </button>
        </div>

        <ErrorBoundary>
          {tipo && (
            <UploadPlanilha
              PJ={tipo === "PJ"}
              onEntryDataProcessed={setDataEntry}
              onLeaveDataProcessed={setDataLeave}
              setPrograma={setPrograma}
              setEmpresa={setEmpresa}
            />
          )}

          {mostrarPDFResumo && (
            <PDFResumoCategorias
              entrada={dataEntry}
              saida={dataLeave}
              PJ={tipo === "PJ"}
              programa={programa}
              empresa={empresa}
              onFinalizado={() => setMostrarPDFResumo(false)}
            />
          )}
        </ErrorBoundary>
      </div>
    </LoadingProvider>
  );
};

export default PDFGeneratorPage;