import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import './ConRight.css';

function ConRight({ handleSubmit }) {
    const cpfRef = useRef(null); // Referência para o campo de telefone
    const senhaRef = useRef(null);
    const [telefone, setTelefone] = useState(''); // Estado para telefone formatado
    const [plainTelefone, setPlainTelefone] = useState(''); // Estado para telefone em plain text
   
    const navigate = useNavigate();

    // Função para aplicar a máscara de telefone
    const formatTelefone = (value) => {
        let onlyNumbers = value.replace(/\D/g, ''); // Remove caracteres não numéricos

        if (onlyNumbers.length > 11) onlyNumbers = onlyNumbers.slice(0, 11); // Limita a 11 dígitos

        if (onlyNumbers.length <= 11) {
            // Aplica a máscara
            onlyNumbers = onlyNumbers.replace(/(\d{2})(\d)/, '($1) $2');
            onlyNumbers = onlyNumbers.replace(/(\d{5})(\d)/, '$1-$2');
        }
        
        return onlyNumbers;
    };

    const handleTelefoneChange = (e) => {
        const plainValue = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
        setPlainTelefone(plainValue); // Atualiza o valor "plain text" para envio
        setTelefone(formatTelefone(plainValue)); // Aplica a máscara e atualiza o estado de exibição
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const senha = senhaRef.current.value;
        handleSubmit(plainTelefone, senha); // Envia o telefone em "plain text"
    };

    const handleNavigateGenerator = () => {
        navigate("/automocao");
    };

    return (
        <div className="ConRight">
            <div className='main_div_right'>
                <div className='row inner_div_right'>
                    <h5 className='col s12 login_title'>Login</h5>
                    <form className="col s12 form_login" onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col s12">
                                <label htmlFor="cpf">Telefone</label>
                                <input
                                    id="cpf"
                                    type="text"
                                    className="validate"
                                    required
                                    autoComplete="off"
                                    ref={cpfRef}
                                    value={telefone} // Mostra o telefone formatado
                                    onChange={handleTelefoneChange} // Formata conforme o usuário digita
                                />
                            </div>
                            <div className="col s12">
                                <label htmlFor="senha">Senha</label>
                                <input
                                    id="senha"
                                    type="password"
                                    className="validate"
                                    required
                                    autoComplete="off"
                                    ref={senhaRef}
                                />
                            </div>
                            <div className="col s6 div_btn_form_right">
                                <button
                                    className="waves-effect waves-light waves-white btn left btn_form_right"
                                    onClick={handleNavigateGenerator}
                                >
                                    Gerador PDF
                                </button>
                            </div>
                            <div className="col s6 div_btn_form_right">
                                <button
                                    className="waves-effect waves-light waves-white btn right btn_form_right"
                                    type="submit"
                                >
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ConRight;