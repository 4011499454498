// src/components/common/GlobalLoader.jsx
import React from "react";
import { useLoading } from "../../contexts/LoadingContext";

import "./GlobalLoader.css";

const GlobalLoader = () => {
  const { isLoading, loadingMessage } = useLoading();

  if (!isLoading) return null;

  return (
    <div className="global-loader-overlay">
      <div className="global-loader-spinner"></div>
      <div className="global-loader-message">
        Por favor, aguarde - {loadingMessage}
        <span className="loader-dots">
          <span className="dot dot1">.</span>
          <span className="dot dot2">.</span>
          <span className="dot dot3">.</span>
        </span>
      </div>
    </div>
  );
};

export default GlobalLoader;