import { StyleSheet } from "@react-pdf/renderer";

const pdfStyles = StyleSheet.create({
  page: {
    fontSize: 12,
    backgroundColor: "#ffffff",
    fontFamily: "Helvetica",
  },

  upBar: {
    width: 600,
    height: 5,
    margin: 0,
    padding: 0,
    backgroundColor: "#00afa9",
  },

  mainDiv:{
    marginTop: 2,
  },

  divTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 10,
    paddingLeft: 10,
    marginBottom: 0,
  },

  divTitleAux: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  image: {
    width: 200,
  },

  divTextVertical: {
    transform: "rotate(-90deg)",
    position: "absolute",
    top: 124,
    left: -30,
    backgroundColor: "#ffffff",
    width: 100,
    justifyContent: "center",
    alignItems: "center",
  },

  verticalText: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#00afa9",
    backgroundColor: "#ffffff",
    paddingVertical: 4,
    textAlign: "center",
  },  

  divMid: {
    position: 'relative',
    height: 250,
  },

  divMidInner: {
    flexDirection: "row", 
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: "100%",
  },

  title: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 0,
    color: "#333",
  },

  subtitle: {
    fontSize: 10,
    fontWeight: "bold",
    textAlign: 'left',
    color: "#e91d75",
  },

  section: {
    marginBottom: 12,
    paddingBottom: 6,
    borderBottom: "1px solid #eee",
  },

  label: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#222",
    marginBottom: 2,
  },

  paragraph: {
    marginBottom: 10,
    lineHeight: 1.4,
    textAlign: "justify",
  },

  imagePlaceholder: {
    height: 150,
    border: "1pt dashed #999",
    marginVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  indicadorBox: {
    marginTop: 10,
    marginBottom: 20,
    padding: 10,
    border: "1pt solid #ccc",
  },

  indicadorTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 4,
  },

  divBars: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
  },

  divBarsAux: {
    flexDirection: 'row',
  },

  barSection: {
    zIndex: 1,
    border: "1pt solid #ccc",
    borderRadius: 6,
    paddingLeft: 15,
    paddingRight: 5,
    paddingTop: 10,
    paddingBottom: 2,
    marginVertical: 10,
    marginLeft: 20,
  
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
  },  

  barRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },

  barLabel: {
    width: 90,
    fontSize: 12,
    fontWeight: "bold",
  },

  barTrack: {
    width: 100,
    height: 12,
    backgroundColor: "#eaeaea",
    borderRadius: 10,
    overflow: "hidden",
    marginRight: 5,
  },

  barFillEntrada: {
    height: 12,
    backgroundColor: "#e91d75",
  },

  barFillSaida: {
    height: 12,
    backgroundColor: "#00afa9",
  },

  barValue: {
    fontSize: 14,
    width: 20,
    textAlign: "right",
  },

  scoreBox: {
    width: 140,
    marginLeft: 0,
    marginVertical: 10,
    padding: 5,
    backgroundColor: "#f9f9f9",
    justifyContent: "center",
    alignItems: "center",

    height: "100%",
  },
  
  scoreTitle: {
    fontSize: 10,
    fontWeight: "bold",
  },
  
  scoreItem: {
    fontSize: 10,
    marginBottom: 4,
  },

  upperGraphs: {
    width: "90%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 5,
  },
  
  upperGraphsTopRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
  },
  
  graphDelta: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#009688",
  },
  
  graphPipe: {
    fontSize: 12,
    color: "#999",
    paddingHorizontal: 2,
  },
  
  graphDeltaRaw: {
    fontSize: 8,
    color: "#009688",
  },
  
  upperGraphsCircles: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 50,
    gap: 5,
    marginTop: 6,
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 2,
  },
  
  graphWithValue: {
    position: "relative",
    width: "45%",
    height: 45,
    alignItems: "center",
  },
  
  semiGraph: {
    width: "100%",
    height: "100%",
  },
  
  graphValue: {
    position: "absolute",
    top: 12,
    left: 17,
    fontSize: 14,
    fontWeight: "bold",
    color: "#333",
  },
  
  graphLabel: {
    fontSize: 8,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 1,
    textAlign: "center",
  },
  
  graphLine: {
    width: "90%",
    height: 1,
    backgroundColor: "#ccc",
    marginBottom: 6,
    alignSelf: "center",
  },

  bottomGraphs: {
    width: "90%",
    flexDirection: "column",
    gap: 5,
    marginTop: 5,
  },
  
  indicatorBox: {
    width: "100%",
    padding: 2,
    border: "1pt solid #ccc",
    borderRadius: 6,
    alignItems: "center",
  },
  
  indicatorHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  
  indicatorPercent: {
    fontSize: 8,
    fontWeight: "bold",
    color: "#333",
  },
  
  indicatorRaw: {
    fontSize: 7,
    color: "#777",
    marginLeft: 4,
  },
  
  indicatorCircles: {
    flexDirection: "row",

    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: 5,

    marginBottom: 4,
    paddingHorizontal: 6,
  },
  
  circleGraphWrapper: {
    position: "relative",
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  
  circleGraph: {
    width: "100%",
    height: "100%",
  },
  
  circleValue: {
    position: "absolute",
    top: 11.5,
    left: 12.5,
    fontSize: 10,
    fontWeight: "bold",
    color: "#333",
  },
  
  circleArrow: {
    position: "absolute",
    bottom: 8,
    left: 16,
    width: 8,
    height: 8,
  },
  
  indicatorLabelWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 4,
    marginTop: 2,
  },
  
  arrowLabelIcon: {
    width: 8,
    height: 8,
  },
  
  indicatorLabel: {
    fontSize: 7,
    color: "#444",
  },
  
  indicatorCategory: {
    fontSize: 9,
    fontWeight: "bold",
    marginTop: 2,
    marginBottom: 2,
  },
  
  indicatorLine: {
    width: "90%",
    height: 2,
    backgroundColor: "#bbb",
    marginTop: 4,
  },  

  userDataBox: {
    marginLeft: 0,
    marginVertical: 20,
    gap: 20,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "80%",
    width: 180,
  },

  userDataLabel: {
    fontSize: 9,
    fontWeight: "bold",
    marginBottom: 2,
  },

  userDataTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#e91d75",
  },

  legendBox: {
    marginTop: 10,
    gap: 8,
  },
  
  legendItem: {
    marginBottom: 4,
  },
  
  legendBar: {
    height: 18,
    width: 130,
    borderRadius: 10,
    justifyContent: "center",
    paddingHorizontal: 8,
  },
  
  legendText: {
    fontSize: 10,
    color: "#fff",
    fontWeight: "bold",
  },
  
  legendDate: {
    fontSize: 9,
    color: "#444",
    marginTop: 5,
    marginLeft: 8,
  },

  divBottom: {
    position: 'relative',
    width: "70%",
    marginLeft: 10,
    marginTop: 2,
  },

  aboutBox: {
    marginTop: 20,
    paddingHorizontal: 10,
    width: "90%",
  },
  
  aboutTitleRow: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 2,
  },
  
  aboutIcon: {
    width: 12,
    height: 18,
    marginRight: 6,
  },
  
  aboutTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#e91e63",
  },
  
  aboutText: {
    fontSize: 10,
    lineHeight: 1,
    textAlign: "justify",
    color: "#222",
  },

  divTextVerticalBottom: {
    transform: "rotate(-90deg)",
    position: "absolute",
    top: 231,
    left: -40,
    backgroundColor: "#ffffff",
    width: 100,
    justifyContent: "center",
    alignItems: "center",
  },

  subBarSection: {
    zIndex: 1,
    border: "1pt solid #ccc",
    borderRadius: 6,
    paddingLeft: 15,
    paddingRight: 5,
    paddingTop: 10,
    paddingBottom: 2,
    marginVertical: 10,
    marginLeft: 10,

    flexDirection: "column",
    justifyContent: "space-between",
  },
  
  subBarRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  
  subBarLabel: {
    width: 120,
    fontSize: 11,
    fontWeight: "bold",
  },
  
  subDivBars: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
  },
  
  subDivBarsAux: {
    flexDirection: 'row',
  },
  
  subBarTrack: {
    width: 100,
    height: 10,
    backgroundColor: "#eaeaea",
    borderRadius: 10,
    overflow: "hidden",
    marginRight: 5,
  },
  
  subBarFillEntrada: {
    height: 10,
    backgroundColor: "#e91d75",
  },
  
  subBarFillSaida: {
    height: 10,
    backgroundColor: "#00afa9",
  },
  
  subBarValue: {
    fontSize: 12,
    width: 20,
    textAlign: "right",
  }, 

  subLabelGroup: {
    width: 90,
    marginRight: 8,
    justifyContent: "center",
  },
  
  subTitleLabel: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#666",
  },
  
  subCategoryLabel: {
    fontSize: 11,
    fontWeight: "bold",
    color: "#111",
    marginTop: 2,
  },  

  subCircleSection: {
    marginLeft: 12,
    marginVertical: 10,
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 5,
  },
  
  subDiv: {
    flexDirection: "row",
    gap: 2,
    width: "100%",
    height: 190,
  },

  noteSection: {
    width: "70%",
    marginTop: 12,
    paddingHorizontal: 20,
  },
  
  noteBar: {
    width: 40,
    height: 4,
    backgroundColor: "#00afa9",
    marginBottom: 6,
  },
  
  noteText: {
    fontSize: 9,
    color: "#444",
    lineHeight: 1.4,
    textAlign: "justify",
  },
  
  noteBold: {
    fontWeight: "bold",
    color: "#444",
  },
  
  sideDecoration: {
    position: "absolute",
    bottom: -20,
    right: 0,
    width: 180,
    height: 550,
    zIndex: 0,
  }, 

  explanationContainer: {
    flexDirection: "column",
    gap: 10,
    paddingHorizontal: 20,
    paddingBottom: 10,
    marginTop: 10,
  },
  
  explanationBlock: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 10,
  },
  
  explanationImage: {
    width: 60,
    height: "100%",
    marginTop: 4,
  },
  
  explanationTextContainer: {
    flexDirection: "column",
    flex: 1,
  },
  
  explanationTitleRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 3,
    marginBottom: 2,
  },
  
  explanationIcon: {
    width: 10,
    height: 24,
    marginRight: 6,
  },
  
  explanationTitle: {
    fontSize: 12,
    color: "#e91e63",
  },
  
  explanationTitleBold: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#e91e63",
  },
  
  explanationParagraph: {
    fontSize: 10,
    textAlign: "justify",
    color: "#222",
    lineHeight: 1.25,
  },

  titleDiv: {
    flexDirection: "column",
  },

  dividerLine: {
    width: "100%",
    height: 1,
    backgroundColor: "#ccc",
    alignSelf: "center",
    marginTop: 8,
  },
  
});

export default pdfStyles;